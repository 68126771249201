$gray: rgb(189, 181, 181);
$dark-blue: #53636d;
$background: #fff9f5;
$font-main: #0b0e11;
$darker-white: #bfb09b;
$lightblue: #c2dde6;
$lightblue-darker: #799da8;
$orange: #e05915;
$orange-darker: #9e3602;
$orange-lighter: #f46d2a;
$greyblue: #bccbde;
$greyblue-darker: #778ba6;
$yellow: #f3d107;
$yellow-darker: #ad9500;
$buttonblue: #ceac03;

@import './navigation.scss';
@import './base.scss';
@import './home.scss';
@import './about.scss';
@import './data.scss';
@import './user.scss';
@import './contact.scss';
@import './footer.scss';
@import './privacy.scss';
@import './pricing.scss';
@import './create.scss';
@import './notfound.scss';
@import './signup.scss';
@import './imprint.scss';
@import './account.scss';
@import './faq.scss';
@import './blog.scss';
@import './checkout.scss';
@import './radio.scss';
@import './templates/simple-preview.scss';
@import './templates/simple.scss';
@import './templates/black-white-preview.scss';
@import './templates/black-white.scss';
@import './templates/image.scss';
@import './templates/image-preview.scss';
@import './templates/three-colors.scss';
@import './templates/three-colors-preview.scss';
@import './templates/geometric.scss';
@import './templates/geometric-preview.scss';
