.image-preview-container {
    border: 2px solid $font-main;
    height: 370px;
    width: 625px;
    display: flex;
    flex-direction: column;
    background-size: cover;

    .content-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: scroll;
        padding: 40px 30px 20px;
    }

    .content {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .content-left {
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .brand {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 30px;
    }

    .description {
        width: 95%;
        font-size: 16px;
        z-index: 1;
        white-space: pre-line;
        margin-bottom: 20px;
    }

    button {
        border-width: 2px;
        border-style: solid;
        font-size: 12px;
        margin-left: 5px;
        padding: 2px 0;
        width: 40px;
    }

    .preview-img {
        max-width: 100%;
        max-height: 100%;
    }

    .survey {
        width: 100%;
        margin: 0 0 10px;

        .submit-btn {
            width: auto;
            padding: 2px 10px;
            margin: 0 0 25px 0;
        }
    }

    .survey-container {
        display: flex;
        text-align: left;
        margin: 0 0 15px;
        flex-direction: column;

        .survey-question {
            max-width: 85%;
            font-size: 12px;
            margin-bottom: 5px;
        }

        button {
            width: 35px;
            padding: 1px 0;
            margin: 0 6px 0 0;
            font-size: 10px;
        }
    }

    .open-input {
        border-width: 2px;
        padding: 6px;
        background-color: transparent;
        outline: none;
        width: 100%;
        resize: none;
        font-size: 11px;
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--placeholder-color);
        opacity: 0.6;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--placeholder-color);
        opacity: 0.6;
    }
    ::placeholder {
        color: var(--placeholder-color);
        opacity: 0.6;
    }

    .footer {
        font-family: 'Epilogue', sans-serif;
        text-transform: uppercase;
        font-size: 10px;
        margin-top: auto;

        a {
            text-decoration: none;
            color: $font-main;
            font-weight: 600;
        }
    }
}
