.faq-wrapper {
    margin-bottom: 20px;

    @media (max-width: 420px) {
        padding: 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 18px;
        align-items: center;
    }

    .check-img {
        min-height: 15px;
        min-width: 15px;
        max-height: 15px;
        max-width: 15px;
        margin: 5px 8px 0 0;
        align-self: flex-start;
    }

    .faq-question {
        display: flex;
        flex-direction: column;
    }

    .faq-answ {
        font-weight: normal;
        margin-top: 5px;
        font-size: 16px;
    }
}

.terms-wrapper {
    margin-bottom: 20px;

    @media (max-width: 420px) {
        padding: 0;
    }
}

.link-orange {
    text-decoration: none;
    font-weight: bold;
    color: $orange;
}
