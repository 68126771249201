.imprint-container {
    position: relative;

    @media (max-width: 320px) {
        padding-top: 120px;
    }
}

.company-big {
    font-size: 35px;
    font-weight: bold;
    font-family: 'Epilogue', sans-serif;
}

.company-small {
    font-size: 20px;
    font-weight: bold;
}

.imprint-contact {
    font-size: 18px;

    a {
        text-decoration: none;
        color: $font-main;
    }
}

.imprint-txt {
    font-size: 14px;

    @media (max-width: 420px) {
        text-align: center;
    }
}
