.signup-container {
    padding: 110px 50px 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    position: relative;

    .log-details-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (max-width: 420px) {
            align-items: center;
        }
    }

    .contact-txt-big {
        font-size: 45px;
        font-family: 'Epilogue', sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 20px;

        @media (max-width: 320px) {
            margin-top: 10px;
            font-size: 35px;
        }
    }

    .contact-txt-small {
        margin-bottom: 25px;

        @media (max-width: 320px) {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    .contact-form {
        display: flex;
        z-index: 1;
        flex-direction: column;
        margin-top: 10px;

        @media (max-width: 420px) {
            align-items: center;
        }

        form {
            display: flex;
            flex-direction: column;
        }

        .form-txt-small {
            margin-bottom: 20px;
        }

        .form-txt-big {
            font-family: 'Epilogue', sans-serif;
            font-size: 25px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        .form-inp,
        textarea {
            border: 2px solid $lightblue-darker;
            padding: 10px;
            background-color: transparent;
            font-family: 'Oswald', sans-serif;
            color: $font-main;
            outline: none;
            width: 400px;
            margin-bottom: 10px;
            font-size: 15px;

            @supports (-webkit-touch-callout: none) {
                font-size: 16px;
                background-clip: padding-box;
                border-radius: 0;
            }

            @media (max-width: 420px) {
                width: 350px;
            }

            @media (max-width: 375px) {
                width: 330px;
            }

            @media (max-width: 320px) {
                width: 290px;
            }

            @media (max-width: 290px) {
                width: 250px;
            }
        }

        .form-inp::placeholder,
        textarea::placeholder {
            color: $font-main;
            text-transform: uppercase;
        }

        textarea {
            resize: none;
        }

        .form-btn {
            cursor: pointer;
            transition: 0.4s;
            width: 200px;

            &:hover {
                background-color: lighten($buttonblue, 5%);
                border-color: lighten($buttonblue, 5%);
            }
        }

        .sign-btn {
            border: 2px solid $buttonblue;
            background-color: $buttonblue;
            color: $font-main;
            padding: 10px;
            margin-bottom: 10px;
            display: flex;
            height: 46.5px;
            justify-content: center;

            @media (max-width: 320px) {
                padding: 7px;
            }
        }

        ul {
            font-size: 14px;
            padding-left: 20px;
        }

        li::marker {
            color: $dark-blue;
        }

        @media (max-width: 320px) {
            width: 290px;
        }

        @media (max-width: 290px) {
            width: 250px;
        }

        .checkbox {
            --background: transparent;
            --border: #799da8;
            --border-hover: #799da8;
            --border-active: #799da8;
            --tick: #fff;
            position: relative;
            margin-bottom: 10px;

            input,
            svg {
                width: 18px;
                height: 18px;
            }

            input {
                -webkit-appearance: none;
                -moz-appearance: none;
                position: relative;
                outline: none;
                background: var(--background);
                border: none;
                margin: 0 5px 0 0;
                padding: 0;
                cursor: pointer;
                border-radius: 4px;
                transition: box-shadow 0.3s;
                box-shadow: inset 0 0 0 var(--s, 2px) var(--b, var(--border));
                &:hover {
                    --s: 2px;
                    --b: var(--border-hover);
                }
                &:checked {
                    --b: var(--border-active);
                }
            }

            svg {
                pointer-events: none;
                fill: none;
                stroke-width: 2px;
                stroke: var(--stroke, var(--border-active));
                position: absolute;
                left: 0;
                width: 18px;
                height: 18px;
                transform: scale(var(--scale, 1)) translateZ(0);
            }

            &.path {
                input {
                    &:checked {
                        --s: 2px;
                        transition-delay: 0.4s;
                        & + svg {
                            --a: 16.1 86.12;
                            --o: 102.22;
                        }
                    }
                }
                svg {
                    stroke-dasharray: var(--a, 86.12);
                    stroke-dashoffset: var(--o, 86.12);
                    transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                }
            }
        }
    }

    .sign-error {
        color: $orange;
        font-weight: bold;
        margin-bottom: 7px;
    }

    .signup-txt {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;

        @media (max-width: 1180px) {
            margin: 25px 0 15px;
        }
    }
}

.recovery-txt {
    width: 400px;
    margin-bottom: 20px;
    font-size: 15px;

    @media (max-width: 420px) {
        width: auto;
    }
}

.credit-txt {
    font-size: 12px;
    margin-top: -15px;
    margin-bottom: 20px;
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
    color: #799da8;
}

.lds-ring {
    width: 25px;
    height: 25px;
}
.lds-ring div {
    position: absolute;
    width: 25px;
    height: 25px;
    border: 4px solid $font-main;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $font-main transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
