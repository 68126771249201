.user-container {
    padding-top: 120px;
    padding-bottom: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    align-content: flex-start;
    overflow: hidden;
    height: 100%;

    .card {
        width: 250px;
        height: 200px;
        border: 2px solid $font-main;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 0 25px 25px;
        padding: 0;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            color: $font-main;
        }

        @media (max-width: 625px) {
            width: 100%;
        }

        @media (max-width: 420px) {
            height: 150px;
        }
    }

    .icon {
        font-size: 25px;
        margin-bottom: 10px;
        color: $orange;
    }

    .page-name {
        font-size: 20px;
        font-weight: bold;
        margin: auto 5px 5px;
        text-align: center;
    }

    .details-btn {
        background-color: $lightblue-darker;
        padding: 7px 0;
        width: 100%;
        margin-top: auto;
        border: none;
        transition: 0.4s;

        &:hover {
            background-color: darken($lightblue-darker, 5%);
        }

        a {
            color: $background;
        }
    }

    .publish-info {
        background-color: $orange;
        padding: 2px 0;
        width: 100%;
        margin-top: auto;
        margin-bottom: -44px;
        color: $background;

        text-transform: uppercase;
        font-size: 13px;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 1px;
        text-align: center;

        @media (max-width: 420px) {
            margin-bottom: -20px;
        }
    }

    .upgrade-user {
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-transform: uppercase;
        padding: 0 10px;
        font-size: 15px;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 1px;
    }
}

.details-container {
    padding-top: 80px;
    padding-bottom: 80px;
    height: 100%;

    @media (max-width: 420px) {
        padding: 90px 10px 70px;
    }

    .date {
        text-transform: uppercase;
        color: $gray;
        font-size: 14px;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 1px;
        font-weight: normal;
    }

    .question-data {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;

        @media (max-width: 1025px) {
            flex-direction: column;
        }
    }

    .question {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .stats-container {
        text-transform: uppercase;
        margin-right: 15px;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 1px;
        line-height: 20px;
        font-size: 14px;

        @media (max-width: 580px) {
            font-size: 13px;
        }

        @media (max-width: 565px) {
            font-size: 12px;
        }

        @media (max-width: 420px) {
            align-self: flex-start;
            margin-top: 5px;
        }
    }

    .data-container {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 420px) {
            flex-direction: column-reverse;
        }
    }

    .left-wrapper,
    .right-wrapper {
        width: 50%;

        @media (max-width: 1025px) {
            width: 100%;
        }
    }

    .right-wrapper {
        margin-left: 30px;

        @media (max-width: 1025px) {
            margin: 50px 0 0;
        }
    }

    .download-info {
        margin-top: 10px;
    }

    .download-btn {
        cursor: pointer;
        transition: 0.4s;
        padding: 3px 20px;
        font-size: 13px;
        letter-spacing: -0.1px;
        margin-top: 10px;
        color: $font-main;
    }

    .pdf-btn {
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        outline: none;
        cursor: pointer;
        font-family: 'Oswald', sans-serif;
        background-color: $buttonblue;
        text-decoration: none;
        color: $font-main;
        width: 270px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: lighten($buttonblue, 5%);
        }

        @media (max-width: 620px) {
            width: 100%;
        }
    }

    .feedback-btn {
        background-color: $buttonblue;
        margin-left: 10px;
        border: none;

        &:hover {
            background-color: lighten($buttonblue, 5%);
        }

        @media (max-width: 620px) {
            margin-left: 0;
        }

        @media (max-width: 420px) {
            margin-bottom: 20px;
        }
    }

    .btns-details {
        margin-top: 10px;
        display: flex;

        @media (max-width: 620px) {
            flex-direction: column;
        }
    }

    .survey-container {
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0 30px;
    }

    .answers {
        flex-direction: column;

        .answ {
            white-space: pre-line;
        }
    }

    .survey-question {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .survey-title {
        margin-top: 0px;
    }

    .survey {
        margin: 0 45px 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 420px) {
            display: block;
            margin-right: 0;
            width: 100%;
        }
    }

    .survey-open {
        display: block;
        width: 90%;
        margin-right: 0px;

        @media (max-width: 950px) {
            width: 100%;
        }
    }

    .publish-btn {
        background-color: $darker-white;
        color: $font-main;
        margin-top: 0;
        align-self: flex-start;
        width: 100px;
        border: none;

        @media (max-width: 420px) {
            padding: 5px 0px;
            width: 80px;
        }
    }

    .section-txt {
        @media (max-width: 320px) {
            font-size: 30px;
        }
    }

    .tips {
        margin: 50px 0 20px;
    }

    .icon-wrapper {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        background-color: $darker-white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        margin-top: -5px;
    }

    .icon {
        font-size: 10px;
        color: $background;
    }

    .publish {
        background-color: $orange;
        padding: 5px;
        font-size: 12px;
        font-weight: normal;
        text-align: center;
        color: $background;
        margin-bottom: 2px;
        max-width: 120px;

        @media (max-width: 420px) {
            color: $orange;
            background-color: transparent;
            font-weight: bold;
            text-align: start;
            padding: 0;
            font-size: 13px;
        }
    }

    .tip-link {
        font-weight: bold;
        color: $font-main;
    }
}

.feedback-container {
    padding: 25px 50px;

    @media (max-width: 420px) {
        padding: 25px 10px;
    }

    .details-container {
        padding: 30px 0;
        background: none;

        @media (max-width: 420px) {
            padding: 15px 0;
        }
    }

    .footer {
        font-family: 'Epilogue', sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        margin-top: 25px;

        a {
            text-decoration: none;
            color: $font-main;
            font-weight: 600;
        }
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .safari-margin {
            margin-bottom: -0.2px;
        }

        .safari-btn {
            margin-left: -0.2px;
        }

        .safari-input-btn {
            font-size: 13px;
        }
    }
}

.add-payment {
    padding: 20px;
    background-color: $buttonblue;
    color: $font-main;
    margin-top: 20px;
    padding: 10px;
    height: 44px;
    max-width: 180px;
    min-width: 180px;
    cursor: pointer;
    transition: 0.4s;
    font-size: 13px;
    letter-spacing: -0.1px;
    border: 2px solid $buttonblue;

    &:hover {
        background-color: lighten($buttonblue, 5%);
        border-color: lighten($buttonblue, 5%);
    }

    @media (max-width: 420px) {
        max-width: 100%;
        min-width: 100%;
    }
}
