@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Erica+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: $font-main;
    background-color: $background;
}

.container {
    padding-right: 70px;
    padding-left: 70px;

    @media (max-width: 1150px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media (max-width: 420px) {
        padding-right: 30px;
        padding-left: 30px;
    }

    @media (max-width: 320px) {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.link {
    text-decoration: none;
}

button {
    text-transform: uppercase;
    border-width: 0;
    padding: 0 0 5px 0;
    color: $font-main;
    font-size: 15px;
    outline: none;
    background-color: transparent;
    border: 0 0 1px 0;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}

input {
    border-radius: 0;
}
