.geometric-preview-container {
    border: 2px solid $font-main;
    height: 370px;
    width: 625px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    justify-content: space-between;

    .content-wrapper {
        display: flex;
        flex-direction: column;
        padding: 83px 30px 0px;
    }

    .content {
        display: flex;
        margin-bottom: 20px;
    }

    .content-left {
        width: 100%;
        display: flex;
    }

    .brand {
        min-height: 42.98px;
        height: 42.98px;
        display: flex;
        align-items: center;
        padding: 0 30px;
        position: fixed;
        width: 621px;
        z-index: 1;
    }

    .description {
        width: 50%;
        margin-right: 15px;
        font-size: 16px;
        white-space: pre-line;
    }

    .template-footer {
        display: flex;
        padding: 10px 30px;
        justify-content: flex-start;
        font-family: 'Epilogue', sans-serif;
        text-transform: uppercase;
        font-size: 10px;
        position: sticky;

        a {
            text-decoration: none;
            font-weight: 600;
        }
    }

    .footer-decor {
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: 35px 35px;
        content: ' ';
        display: block;
        height: 35px;
        position: sticky;
    }

    button {
        border-width: 2px;
        border-style: solid;
        font-size: 12px;
        padding: 2px 0;
        margin: 5px 0 0 0;
    }

    .survey {
        margin: 0 0 10px;
        width: 100%;

        .submit-btn {
            width: auto;
            padding: 2px 10px;
        }
    }

    .description:empty {
        display: none;
    }

    .description:empty + .survey {
        width: 75%;
    }

    .survey-container {
        display: flex;
        align-items: flex-start;
        margin: 0 0 15px;
        flex-direction: column;
        width: 100%;

        .survey-question {
            font-size: 12px;
            margin-bottom: 5px;
        }

        button {
            width: 25px;
            height: 25px;
            padding: 1px 0;
            margin: 0 6px 0 0;
            font-size: 10px;
        }
    }

    .open-input {
        border-width: 2px;
        padding: 5px;
        background-color: transparent;
        outline: none;
        width: 100%;
        resize: none;
        font-size: 11px;
    }

    .cntr {
        flex-direction: column;
        margin-left: 0;
    }

    .btn-radio {
        margin-left: 0;
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--placeholder-color);
        opacity: 0.6;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--placeholder-color);
        opacity: 0.6;
    }
    ::placeholder {
        color: var(--placeholder-color);
        opacity: 0.6;
    }
}
