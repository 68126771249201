.contact-container {
    padding-top: 150px;
    padding-bottom: 70px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .contact-txt-big {
        font-size: 45px;
        font-family: 'Epilogue', sans-serif;
        font-weight: 700;
        letter-spacing: 2px;

        @media (max-width: 320px) {
            margin-top: 20px;
            font-size: 35px;
        }
    }

    .contact-txt-small {
        margin-bottom: 25px;

        @media (max-width: 420px) {
            padding: 0 30px;
            text-align: center;
        }

        @media (max-width: 320px) {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    .contact-form {
        display: flex;
        flex-direction: column;
        z-index: 1;

        form {
            display: flex;
            flex-direction: column;
        }

        .form-txt-small {
            margin-bottom: 20px;
        }

        .form-txt-big {
            font-family: 'Epilogue', sans-serif;
            font-size: 25px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        input,
        textarea {
            border: 2px solid $lightblue-darker;
            padding: 10px;
            background-color: transparent;
            font-family: 'Oswald', sans-serif;
            color: $font-main;
            outline: none;
            width: 400px;
            margin-bottom: 10px;
            font-size: 15px;

            @supports (-webkit-touch-callout: none) {
                font-size: 16px;
                background-clip: padding-box;
                border-radius: 0;
            }

            @media (max-width: 420px) {
                width: 350px;
            }

            @media (max-width: 375px) {
                width: 330px;
            }

            @media (max-width: 320px) {
                width: 290px;
            }

            @media (max-width: 290px) {
                width: 250px;
            }
        }

        input::placeholder,
        textarea::placeholder {
            color: $font-main;
            text-transform: uppercase;
        }

        textarea {
            resize: none;
        }

        .form-btn {
            cursor: pointer;
            transition: 0.4s;
            width: 200px;

            &:hover {
                background-color: lighten($buttonblue, 5%);
                border-color: lighten($buttonblue, 5%);
            }
        }

        .sign-btn {
            border: 2px solid $buttonblue;
            background-color: $buttonblue;
            color: $font-main;
            padding: 10px;
            margin-bottom: 10px;

            @media (max-width: 420px) {
                padding: 7px;
            }
        }

        @media (max-width: 420px) {
            align-items: center;
        }
    }

    .sign-error {
        color: $orange;
        font-weight: bold;
        margin-bottom: 7px;
    }

    .checkbox {
        --background: transparent;
        --border: #799da8;
        --border-hover: #799da8;
        --border-active: #799da8;
        --tick: #fff;
        position: relative;
        margin-bottom: 10px;

        input,
        svg {
            width: 18px;
            height: 18px;
        }

        input {
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            outline: none;
            background: var(--background);
            border: none;
            margin: 0 5px 0 0;
            padding: 0;
            cursor: pointer;
            border-radius: 4px;
            transition: box-shadow 0.3s;
            box-shadow: inset 0 0 0 var(--s, 2px) var(--b, var(--border));
            &:hover {
                --s: 2px;
                --b: var(--border-hover);
            }
            &:checked {
                --b: var(--border-active);
            }
        }

        svg {
            pointer-events: none;
            fill: none;
            stroke-width: 2px;
            stroke: var(--stroke, var(--border-active));
            position: absolute;
            left: 0;
            width: 18px;
            height: 18px;
            transform: scale(var(--scale, 1)) translateZ(0);
        }

        &.path {
            input {
                &:checked {
                    --s: 2px;
                    transition-delay: 0.4s;
                    & + svg {
                        --a: 16.1 86.12;
                        --o: 102.22;
                    }
                }
            }
            svg {
                stroke-dasharray: var(--a, 86.12);
                stroke-dashoffset: var(--o, 86.12);
                transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
            }
        }
    }
}
