.data-section {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @media (max-width: 790px) {
        height: auto;
    }

    .home-wrapper {
        @media (max-width: 790px) {
            padding-top: 25px;
        }
    }

    .form-txt-small {
        @media (max-width: 320px) {
            font-size: 15px;
        }
    }

    .form-txt-big {
        font-family: 'Epilogue', sans-serif;
        font-size: 30px;
        margin-bottom: 15px;
        font-weight: 700;
    }

    .check-img {
        height: 19px;
        width: 19px;
    }

    .feature {
        display: flex;
        margin-bottom: 15px;
        font-size: 20px;
        font-family: 'Epilogue', sans-serif;
        font-weight: 700;

        img {
            margin-right: 10px;
        }

        @media (max-width: 790px) {
            font-size: 18px;
        }
    }
}

.top-btn {
    z-index: 1;
}

.data-right {
    padding: 25px 0 25px 0;
    width: 35%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 790px) {
        width: 100%;
        height: auto;
        padding: 20px 0px 95px;
    }

    @media (max-width: 510px) {
        padding: 0px 0px 95px;
    }
}

.data-left {
    background-color: $background;
    padding: 25px 0 25px 0;
    width: 55%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .img-bottom {
        position: absolute;
        bottom: 50px;
        left: 25vw;
        width: 30vw;

        @media (max-width: 775px) {
            display: none;
        }
    }

    @media (max-width: 790px) {
        width: 100%;
        height: auto;
    }
}

.data-form {
    margin-left: 0 !important;
    width: 100% !important;

    .email-input {
        max-width: 80%;
        margin-right: -2px;

        @supports (-webkit-touch-callout: none) {
            font-size: 16px;
            border-radius: 0;
            background-clip: padding-box;
        }
    }

    .form-txt-big {
        margin-top: -40px;

        @media (max-width: 790px) {
            margin-top: 0px;
        }

        @media (max-width: 420px) {
            font-size: 24px;
        }

        @media (max-width: 320px) {
            font-size: 22px;
        }
    }

    @media (max-width: 1100px) {
        padding-bottom: 30px;
    }

    @media (max-width: 790px) {
        padding-bottom: 10px;
        margin-top: 0px;
    }

    @media (max-width: 420px) {
        margin-top: 20px;
    }
}

.theme-info {
    margin-top: 20px;
}

.theme-click {
    font-size: 13px;
    text-transform: uppercase;

    @media (max-width: 790px) {
        margin-top: 10px;
    }
}
