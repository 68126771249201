.compact-picker {
    border: 2px solid $font-main;
    background-color: $background;
    box-shadow: rgba(255, 255, 255, 1) 0px 2px 10px, rgba(255, 255, 255, 1) 0px 2px 5px;
}

.create-container {
    padding-top: 80px;
    padding-bottom: 80px;

    @media (max-width: 420px) {
        padding: 80px 10px 35px;
    }
}

.error {
    font-weight: bold;
    color: $orange;
}

.create-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1235px) {
        flex-direction: column-reverse;
    }
}

.create-btn {
    background-color: $buttonblue;
    color: $font-main;
    padding: 10px;
    cursor: pointer;
    transition: 0.4s;
    width: 200px;
    border: none;
    display: flex;
    justify-content: center;

    &:hover {
        background-color: lighten($buttonblue, 5%);
    }

    @media (max-width: 540px) {
        align-self: center;
    }

    @media (max-width: 420px) {
        margin-bottom: 30px;
    }
}

.font-btn,
.template-btn {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    border: 2px solid $font-main;
    margin: 10px 10px 0 0;
    text-transform: lowercase;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-out;
    padding: 0;
}

.template-btn {
    background-size: cover;
    border-radius: 50px;
}

.radius-btn {
    border: 2px solid black;
    padding: 2px 10px;
    font-size: 12px;
    margin: 5px 10px 0 0;
}

.epic-wrapper {
    display: flex;
    flex-direction: row;
    display: space-between;
    width: 100%;
    margin-bottom: 20px;

    .story-bullet {
        margin-right: 10px;
        content: counter(item);
        background: $yellow-darker;
        border-radius: 100%;
        color: white;
        width: 21px;
        height: 21px;
        text-align: center;
        display: inline-block;
        font-weight: bold;
        position: absolute;
        font-size: 14px;
        padding-top: 2px;
    }

    .epic-points {
        font-size: 15px;
        color: $greyblue-darker;
        font-weight: bold;
        margin: 5px 0 10px 30px;
    }

    .epic-points-total {
        font-size: 20px;
        color: $orange-darker;
        font-weight: bold;
        margin: 15px 0 20px 0;
    }

    .story-wrapper {
        display: flex;
        margin-top: 12px;
    }

    .epic-story {
        font-size: 18px;
        margin-left: 30px;
        font-weight: bold;
    }

    .epic-criteria {
        font-size: 15px;
        margin-left: 30px;
        font-weight: bold;
        margin-top: 7px;
        color: $dark-blue;
    }

    .epic-section {
        font-size: 20px;
    }

    @media (max-width: 1135px) {
        flex-direction: column;
        margin-bottom: 30px;
    }
}

.epic-container {
    width: 65%;

    @media (max-width: 1135px) {
        width: 100%;
        margin-top: 35px;
    }
}

.form-container {
    width: 35%;
    margin-right: 25px;

    @media (max-width: 1135px) {
        width: 100%;
        margin-right: 0;
    }
}

.preview-container {
    position: fixed;
    right: 70px;

    @media (max-width: 1235px) {
        position: relative;
        right: 0px;
    }

    @media (max-width: 725px) {
        display: none;
    }
}

.preview-display-info {
    display: none;

    p {
        color: #c76325;
        font-weight: bold;
    }

    @media (max-width: 725px) {
        display: block;
    }
}

.section-txt {
    font-size: 35px;
    margin: 0px 0 8px;
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
}

.web-content {
    min-height: 200px;
    border: 1px solid $font-main;
    padding: 0 15px;
}

.create-form-info {
    font-size: 11px;
    color: rgb(102, 102, 102);
    margin-top: 0px;

    a {
        text-decoration: none;
    }
}

.question-container {
    display: flex;
    align-items: center;
}

.txt-smaller {
    display: block;
    margin: 10px 0 -7px;
    font-size: 15px;
}

.form-label {
    display: block;
    font-size: 14px;
    margin: 15px 0 5px;
    margin-right: 5px;
}

.templates-container {
    display: flex;

    @media (max-width: 420px) {
        flex-wrap: wrap;
    }
}

.template-name {
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 13px;
    transition: all 0.2s ease-out;
    width: 40px;
    line-height: 11px;
    cursor: pointer;
}

.input-label {
    @media (max-width: 360px) {
        font-size: 16px;
    }
}

.form-input,
.input-txt,
.msg-txt,
.input-label,
.msg-input {
    display: block;
    padding-top: 10px;
    border: none;
    font-size: 22px;

    &::placeholder {
        opacity: 1;
        color: gray;
    }
}

.msg-input:focus,
.form-input:focus {
    outline: 0;
}

/* Question */

.msg-input {
    resize: none;
    padding-top: 0;
}

.form-input,
.msg-input {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-weight: 300;
    border-radius: 2px;
    margin: 0;
    border: none;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    transition: padding-top 0.2s ease, margin-top 0.2s ease;
    overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */

    @media (max-width: 585px) {
        font-size: 21px;
    }

    @media (max-width: 420px) {
        font-size: 19px;
    }

    @media (max-width: 375px) {
        font-size: 17px;
    }

    @media (max-width: 360px) {
        font-size: 16px;
    }
}
/* Underline and Placeholder */

.form-input + .input-label,
.msg-input + .msg-label {
    display: block;
    position: relative;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    width: 10%;
    border-top: 2px solid #e05915;
    -webkit-transition: width 0.4s ease;
    transition: width 0.4s ease;
    height: 0px;
}

.form-input:focus + .input-label,
.msg-input:focus + .msg-label {
    width: 80%;
}

.form-input:focus + .input-label > .input-txt,
.form-input:valid + .input-label > .input-txt,
.form-input:invalid + .input-label > .input-txt {
    top: -80px;
    font-size: 18px;
    font-weight: 500;
    z-index: -1;

    @media (max-width: 360px) {
        font-size: 17px;
    }
}

.msg-input:focus + .msg-label > .msg-txt,
.msg-input:valid + .msg-label > .msg-txt,
.msg-input:invalid + .msg-label > .msg-txt {
    top: -115px;
    font-size: 18px;
    font-weight: 500;
    z-index: -1;

    @media (max-width: 360px) {
        font-size: 17px;
    }
}

.form-input:valid + .input-label,
.msg-input:valid + .msg-label {
    border-color: #799da8;
}

.form-input:invalid,
.msg-input:invalid {
    box-shadow: none;
}

.form-input + .input-label > .input-txt,
.msg-input + .msg-label > .msg-txt {
    font-weight: 300;
    margin: 0;
    position: absolute;
    font-size: 22px;
    top: -40px;
    left: 0px;
    z-index: -1;
    -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
    transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

.subsection-title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;

    @media (max-width: 360px) {
        font-size: 17px;
    }
}

.integration-btn,
.copy-btn {
    max-width: 120px;
    min-width: 120px;
    background-color: transparent;
    border: 2px solid #0b0e11;
    color: #0b0e11;
    padding: 5px;
    cursor: pointer;
    transition: 0.4s;
    font-size: 13px;
    letter-spacing: -0.1px;
    transition: 0.4s;
    margin-bottom: 5px;

    &:hover {
        background-color: #0b0e11;
        color: #fff9f5;
    }

    &:disabled {
        border-color: $darker-white;
        color: $darker-white;
        cursor: not-allowed;

        &:hover {
            border-color: $darker-white;
            color: $darker-white;
            background-color: transparent;
        }
    }
}

.integration-title {
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 10px;
}

.integration-description {
    margin-bottom: 3px;
}

.integration-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    @media (max-width: 1135px) {
        flex-direction: row;
        margin-top: 10px;
        margin-left: 0;

        button {
            margin-right: 5px;
        }
    }

    @media (max-width: 450px) {
        flex-direction: column;
        margin-top: 10px;

        button {
            margin-right: 0;
        }
    }
}

.single-epic {
    border-left: 5px solid $greyblue;
    display: flex;
    margin-bottom: 20px;

    .epic-request {
        margin-right: 15px;
        max-width: 400px;
    }

    .epic-actions {
        display: flex;
        flex-direction: column;
    }

    button {
        background-color: $lightblue-darker;
        // color: $background;
        padding: 1px 6px;
        margin-bottom: 4px;
        font-size: 13px;
        cursor: pointer;
        transition: 0.4s;
        border: 2px solid $lightblue-darker;

        &:hover {
            background-color: lighten($lightblue-darker, 5%);
            border-color: lighten($lightblue-darker, 5%);
        }
    }
}
