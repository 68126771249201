.geometric-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;

    .content-wrapper {
        display: flex;
        padding: 90px 50px 0px;

        @media (max-width: 420px) {
            padding: 50px 20px 20px;
        }
    }

    .content {
        display: flex;
        padding-top: 30px;
        align-items: flex-start;
        width: 100%;
    }

    .content-left {
        width: 100%;
        display: flex;

        @media (max-width: 885px) {
            flex-direction: column;
        }
    }

    .brand {
        max-height: 80px;
        min-height: 80px;
        display: flex;
        align-items: center;
        padding: 0 50px;
        position: fixed;
        width: 100%;

        @media (max-width: 420px) {
            padding: 0 20px;
            max-height: 60px;
            min-height: 60px;
        }

        // img {
        //     height: 22px;

        //     @media (max-width: 420px) {
        //         height: 20px;
        //     }
        // }
    }

    .description {
        width: 50%;
        margin-right: 20px;
        white-space: pre-line;
        font-size: 28px;

        @media (max-width: 885px) {
            width: 80%;
            margin-bottom: 35px;
        }

        @media (max-width: 800px) {
            font-size: 22px;
        }

        @media (max-width: 750px) {
            width: 90%;
        }

        @media (max-width: 650px) {
            width: 95%;
        }
    }

    .template-footer {
        display: flex;
        padding: 10px 50px;
        justify-content: flex-start;
        font-family: 'Epilogue', sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        position: sticky;

        @media (max-width: 420px) {
            padding: 10px 20px;
        }

        a {
            text-decoration: none;
            font-weight: 600;
        }
    }

    .footer-decor {
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: 60px 60px;
        content: ' ';
        display: block;
        height: 60px;
        position: sticky;
    }

    .survey {
        width: 100%;

        .submit-btn {
            width: auto !important;
            padding: 4px 15px;
            margin: 5px 0 60px;
            font-size: 16px;
            border: none;

            @media (max-width: 800px) {
                font-size: 15px;
                padding: 3px 15px;
            }

            @media (max-width: 420px) {
                margin: 5px 0 15px;
            }

            @media (max-width: 320px) {
                font-size: 14px;
            }
        }
    }

    .description:empty {
        display: none;
    }

    .description:empty + .survey {
        width: 75%;

        @media (max-width: 885px) {
            width: 100%;
        }
    }

    .survey-container {
        display: flex;
        align-items: flex-start;
        margin: 0 0 25px;
        flex-direction: column;
        width: 100%;

        .survey-question {
            font-size: 18px;
            margin-bottom: 5px;

            @media (max-width: 550px) {
                font-size: 16px;
            }
        }

        button {
            min-width: 45px;
            max-width: 45px;
            min-height: 45px;
            max-height: 45px;
            padding: 1px 0;
            margin: 0 6px 0 0;
            font-size: 13px;
            border-width: 2px;
            border-style: solid;

            @media (max-width: 420px) {
                font-size: 12px;
            }
        }
    }

    .open-input {
        border-width: 2px;
        padding: 10px;
        background-color: transparent;
        outline: none;
        width: 100%;
        resize: none;
        font-size: 16px;
    }

    .submit-txt {
        font-size: 45px;
        width: 60%;
        margin-top: 20px;
    }

    .radio-label {
        font-size: 16px;
        margin-left: 5px;

        @media (max-width: 680px) {
            font-size: 15px;
        }
    }

    .btn-radio {
        margin-left: 0;

        &:not(:first-child) {
            margin-top: 5px;
        }

        @media (max-width: 885px) {
            &:not(:first-child) {
                margin-top: 0;
            }

            &:not(:first-child) {
                margin-left: 20px;
            }
        }

        @media (max-width: 800px) {
            margin-left: 0;

            &:not(:first-child) {
                margin-top: 5px;
                margin-left: 0;
            }

            &:first-child {
                margin-top: 0;
            }
        }

        span {
            @media (max-width: 420px) {
                margin-left: 5px;
            }
        }
    }

    .radio-wrapper {
        display: flex;
        flex-direction: column;

        @media (max-width: 885px) {
            flex-direction: row;
            width: 120%;
        }

        @media (max-width: 800px) {
            width: 100%;
            flex-direction: column;
        }
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--placeholder-color);
        opacity: 0.6;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--placeholder-color);
        opacity: 0.6;
    }
    ::placeholder {
        color: var(--placeholder-color);
        opacity: 0.6;
    }
}
