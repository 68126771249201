.navigation {
    background-color: $background;
    padding: 0px 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2;
    height: 70px;

    @media (max-width: 1150px) {
        padding: 0px 50px;
    }

    @media (max-width: 560px) {
        flex-direction: column;
        padding: 25px 10px;
        height: 100px;
    }
}

.nav-wrap {
    align-items: center;
    display: flex;

    @media (max-width: 510px) {
        padding-bottom: 0px;
    }

    @media (max-width: 375px) {
        margin-top: 10px;
        padding-bottom: 5px;
        background-color: $background;
        width: 100vw;
        display: flex;
        justify-content: center;
    }
}

.nav-link {
    color: $font-main;
    text-transform: uppercase;
    font-size: 15px;
    outline: none;
    border: 0 0 1px 0;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}

.logo {
    height: 20px;
    width: 138.13px;

    @media (max-width: 510px) {
        margin-bottom: 10px;
    }
}

.join-btn {
    $height: 10px;
    $halfheight: ($height/2);
    $thickness: 118%; // increase to make the line thicker
    $offset: 2px;

    position: relative;
    height: $height;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: $height;
        top: 65%;
        background-color: $yellow;
        background-position: top left;
        background-repeat: repeat-x;
        background-size: $height $height;
        z-index: -1;
        margin: 0 -5px 0 5px;
    }

    // $height: 10px;
    // $halfheight: ($height/2);
    // $thickness: 118%; // increase to make the line thicker
    // $offset: 2px;

    // position: relative;
    // height: $height;
    // z-index: 1;

    // &:before,
    // &:after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    // }
    // &:before {
    //     height: ($height - $offset);
    //     top: $thickness;
    //     background: linear-gradient(-135deg, $font-main $halfheight, transparent 0) 0 $halfheight,
    //         linear-gradient(135deg, $font-main $halfheight, transparent 0) 0 $halfheight;
    //     background-position: top left;
    //     background-repeat: repeat-x;
    //     background-size: $height $height;
    // }
    // &:after {
    //     height: $height;
    //     top: 108%;
    //     background: linear-gradient(-135deg, $background $halfheight, transparent 0) 0 $halfheight,
    //         linear-gradient(135deg, $background $halfheight, transparent 0) 0 $halfheight;
    //     background-position: top left;
    //     background-repeat: repeat-x;
    //     background-size: $height $height;
    // }
}

//keeps footer at the bottom
// .general-wrapper {
//     box-sizing: border-box;
//     min-height: 100%;
//     padding: 0 0 80px;
//     position: relative;
//     display: flex;
//     min-height: 100vh;
//     flex-direction: column;
// }
