@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(180deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(180deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.checkout {
    flex-direction: column;

    @media (max-width: 420px) {
        padding-right: 30px;
        padding-left: 30px;
    }

    @media (max-width: 320px) {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.circle-wrapper {
    height: 140px;

    @media (max-width: 420px) {
        height: 110px;
    }
}

.success-circle {
    height: 100px;
    width: 200px;
    background-color: $yellow;
    border-radius: 100px 100px 0 0;
    z-index: 2;
    position: relative;

    -webkit-animation: spin 2s linear 1;
    -moz-animation: spin 2s linear 1;
    -ms-animation: spin 2s linear 1;
    -o-animation: spin 2s linear 1;
    animation: spin 2s linear 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    @media (max-width: 420px) {
        height: 75px;
        width: 150px;
    }
}

.cancel-circle {
    background-color: $yellow;
    border-radius: 0 0 100px 100px;
}

.checkout-txt-big {
    margin-top: 20px;
    font-size: 40px;
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;

    @media (max-width: 1085px) {
        font-size: 35px;
    }

    @media (max-width: 975px) {
        font-size: 30px;
    }

    @media (max-width: 865px) {
        font-size: 28px;
    }

    @media (max-width: 320px) {
        font-size: 26px;
    }
}

.checkout-txt-small {
    margin-top: 5px;
    font-size: 18px;
    text-align: center;

    @media (max-width: 420px) {
        font-size: 17px;
        margin-bottom: 15px;
    }
}
