.footer-container {
    width: 100%;
    padding: 0px 70px;
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    font-family: 'Epilogue', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    position: absolute;
    bottom: 0;

    @media (max-width: 1150px) {
        padding: 0px 50px;
    }

    @media (max-width: 725px) {
        flex-direction: column-reverse;
        padding: 10px 10px;
    }

    @media (max-width: 420px) {
        padding: 10px 10px 20px;
    }
}

.footer-links {
    @media (max-width: 725px) {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
    }

    @media (max-width: 420px) {
        justify-content: space-around;
        margin-bottom: 10px;
    }

    @media (max-width: 375px) {
        @supports (-webkit-touch-callout: none) {
            margin-bottom: 20px;
        }
    }
}

.footer-link {
    color: $font-main;
    margin-left: 15px;

    @media (max-width: 725px) {
        margin-left: 0px;
    }
}
