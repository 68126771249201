.pricing-container {
    height: 100vh;
    padding-top: 150px;
    padding-bottom: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 1050px) {
        padding: 120px 50px 70px;
        height: 100%;
    }

    @media (max-width: 510px) {
        padding-top: 125px;
    }

    @media (max-width: 420px) {
        padding: 120px 30px 70px;
    }

    @media (max-width: 320px) {
        padding: 120px 20px 70px;
    }

    .check-img {
        min-height: 10px;
        min-width: 10px;
        max-height: 10px;
        max-width: 10px;
        margin: 5px 8px 0 0;
        align-self: flex-start;
        rotate: 20deg;
    }
}

.pricing-wrapper {
    display: flex;

    @media (max-width: 1050px) {
        flex-direction: column;
    }

    @media (max-width: 420px) {
        max-width: 100%;
        width: 100%;
    }

    .txt {
        font-size: 12px;
        text-align: center;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        align-items: center;
    }

    .txt-big {
        font-size: 30px;
        font-family: 'Epilogue', sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 25px;

        @media (max-width: 420px) {
            font-size: 28px;
        }
    }

    .pricing {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 250px;

        @media (max-width: 1050px) {
            max-width: 100%;
            margin-left: 0;
        }

        @media (max-width: 670px) {
            margin: 25px 0 0;
        }
    }

    .price-container {
        display: flex;
        height: 205px;
        width: 205px;
        flex-direction: column;
        justify-content: space-between;

        .circle {
            height: 200px;
            width: 200px;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: bold;
            border: 10px solid $font-main;
        }

        .circle-color {
            position: absolute;
            background-color: #e2be0b;
            z-index: -1;
            margin-left: 15px;
            margin-top: 10px;
            border: none;
            height: 190px;
            width: 190px;
        }
    }

    .price {
        font-size: 30px;
        letter-spacing: 1.5px;
        font-weight: 800;
    }
}

.btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 15px 0;

    .start-btn {
        background-color: $buttonblue;
        cursor: pointer;
        transition: 0.4s;
        padding: 10px 50px;
        font-size: 13px;
        letter-spacing: -0.1px;
        color: $font-main;
        border: none;

        &:hover {
            background-color: lighten($buttonblue, 5%);
        }

        @media (max-width: 320px) {
            width: 170px;
            padding: 10px 0;
        }
    }

    @media (max-width: 420px) {
        margin: 10px 0 25px;
    }
}

.price-circle {
    height: 80px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
    border-radius: 50%;
    font-family: 'Oswald', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: $background;
    align-self: center;
    text-align: center;
    justify-content: center;
    background-color: $lightblue-darker;
    margin: 10px 0 5px;

    .price {
        font-size: 22px;
        margin-bottom: -4px;
    }

    .currency {
        font-size: 14px;
        margin-top: -4px;
    }
}

.plans {
    display: flex;

    @media (max-width: 850px) {
        flex-direction: column;
    }

    .plan:not(:last-child) {
        margin-right: 10px;

        @media (max-width: 850px) {
            margin-bottom: 20px;
            margin-right: 0;
        }
    }

    .price-title {
        text-align: center;
        background-color: $font-main;
        border: 2px solid $font-main;
        font-size: 20px;
        color: $background;
        padding: 5px 0;
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
    }

    .bottom-container {
        display: flex;
        flex-direction: column;
        height: 250px;
        max-height: 250px;
        padding: 10px;
        border: 2px solid $font-main;
        width: 250px;

        @media (max-width: 850px) {
            height: fit-content;
            max-height: fit-content;
        }

        @media (max-width: 420px) {
            max-width: 100%;
            width: 100%;
        }
    }

    .plan {
        width: 250px;
        max-width: 250px;

        @media (max-width: 600px) {
            max-width: 100%;
            width: 100%;
        }
    }

    ul {
        margin: 15px 0 0;
    }

    li {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .det {
        font-size: 13px;
        margin-top: auto;
    }
}

.pricing-btn {
    margin-top: 20px;
    background-color: #ceac03;
    align-self: center;
    cursor: pointer;
    transition: 0.4s;
    padding: 15px 35px;
    font-size: 14px;
    letter-spacing: -0.1px;
    width: auto;
    color: $font-main;
    margin-top: 20px;
    border: none;

    &:hover {
        background-color: lighten(#ceac03, 5%);
    }
}
