.privacy-container {
    padding: 25px 50px;
    display: flex;
    margin-top: 100px;
    justify-content: center;
}

.privacy-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    z-index: 1;
}

.flex {
    display: flex;

    span {
        margin-left: 5px;
    }
}

.privacy-title {
    font-family: 'Epilogue', sans-serif;
    font-size: 25px;
    margin: 15px 0 5px;
    font-weight: 500;
}

.privacy-subtitle {
    font-family: 'Epilogue', sans-serif;
    font-size: 18px;
    margin: 10px 0 5px;
    font-weight: 500;
}

.privacy-dot {
    height: 350px;
    position: absolute;
    top: 30px;
    right: -70px;
}

.privacy-link-color {
    color: $orange;
    font-weight: bold;
    text-decoration: none;
}

.privacy-link {
    color: $font-main;
}
