.bw-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

    .content-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: scroll;
        padding: 40px 50px 30px;

        @media (max-width: 420px) {
            padding: 20px;
        }
    }

    .content {
        display: flex;
        align-items: flex-start;
        margin-bottom: 35px;
        justify-content: space-between;
    }

    .content-left {
        width: 60%;

        @media (max-width: 950px) {
            width: 100%;
        }
    }

    .content-right {
        display: flex;
        width: 39%;
        align-self: flex-start;
        justify-content: center;

        @media (max-width: 950px) {
            display: none;
        }
    }

    .brand {
        max-height: 80px;
        min-height: 80px;
        display: flex;
        align-items: center;
        padding: 0 50px;

        @media (max-width: 420px) {
            padding: 0 20px;
            max-height: 60px;
            min-height: 60px;
        }

        // img {
        //     height: 22px;

        //     @media (max-width: 420px) {
        //         height: 20px;
        //     }
        // }
    }

    .description {
        font-size: 28px;
        z-index: 1;
        white-space: pre-line;
        margin-bottom: 35px;

        @media (max-width: 950px) {
            width: 80%;
        }

        @media (max-width: 800px) {
            font-size: 22px;
            width: 100%;
        }

        @media (max-width: 320px) {
            font-size: 20px;
        }
    }

    .preview-img {
        max-width: 100%;
        max-height: 100%;
    }

    .small-scr-img {
        display: none;

        @media (max-width: 950px) {
            display: flex;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            margin-bottom: 35px;
        }
    }

    .survey {
        width: 100%;

        .submit-btn {
            width: auto !important;
            padding: 4px 15px;
            margin: 5px 0 60px;
            font-size: 16px;
            border: none;

            @media (max-width: 800px) {
                font-size: 15px;
                padding: 3px 15px;
            }

            @media (max-width: 420px) {
                margin: 5px 0 45px;
            }

            @media (max-width: 320px) {
                font-size: 14px;
            }
        }
    }

    .survey-container {
        display: flex;
        flex-direction: column;
        margin: 0 0 25px;

        .survey-question {
            font-size: 18px;
            margin-bottom: 5px;

            @media (max-width: 550px) {
                font-size: 16px;
            }
        }

        button {
            min-width: 45px;
            max-width: 45px;
            padding: 1px 0;
            margin: 0 6px 0 0;
            font-size: 13px;
            border-width: 2px;
            border-style: solid;

            @media (max-width: 420px) {
                font-size: 12px;
            }
        }

        @media (max-width: 950px) {
            width: 85%;
        }

        @media (max-width: 800px) {
            width: 100%;
        }
    }

    .open-input {
        border-width: 2px;
        padding: 10px;
        background-color: transparent;
        outline: none;
        resize: none;
        font-size: 16px;

        @media (max-width: 420px) {
            font-size: 15px;
        }
    }

    .footer {
        font-family: 'Epilogue', sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        margin-top: auto;

        a {
            text-decoration: none;
            font-weight: 600;
        }
    }

    .submit-txt {
        font-size: 45px;
        width: 60%;
        margin-top: 20px;
    }

    .radio-label {
        font-size: 16px;
        margin-left: 5px;

        @media (max-width: 680px) {
            font-size: 15px;
        }
    }

    .btn-radio {
        margin-left: 0;

        &:not(:first-child) {
            margin-top: 5px;
        }

        @media (max-width: 950px) {
            margin-top: 5px;

            &:not(:first-child) {
                margin-left: 20px;
            }
        }

        @media (max-width: 800px) {
            margin-left: 0;

            &:not(:first-child) {
                margin-top: 5px;
                margin-left: 0;
            }

            &:first-child {
                margin-top: 0;
            }
        }

        span {
            @media (max-width: 420px) {
                margin-left: 5px;
            }
        }
    }

    .radio-wrapper {
        display: flex;
        flex-direction: column;

        @media (max-width: 950px) {
            flex-direction: row;
            width: 120%;
        }

        @media (max-width: 800px) {
            width: 100%;
            flex-direction: column;
        }
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--placeholder-color);
        opacity: 0.6;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--placeholder-color);
        opacity: 0.6;
    }
    ::placeholder {
        color: var(--placeholder-color);
        opacity: 0.6;
    }
}
