.blog-container,
.blog-container-article {
    padding-top: 100px;

    h1 {
        margin-bottom: 0;
    }
}

.blog-container-article {
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    width: auto;

    @media (max-width: 420px) {
        margin: 0 20px;
    }
}

.articles {
    display: flex;
    margin: 20px 0 70px;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 1075px) {
        justify-content: space-evenly;
    }

    @media (max-width: 780px) {
        justify-content: space-between;
    }

    .title {
        display: flex;
        text-align: center;
        flex: 1;
        align-items: center;
        justify-content: center;
        letter-spacing: 0px;
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 10px;
        padding: 8px 10px 0px;
    }

    .bottom-container {
        display: flex;
        flex-direction: column;
        height: 160px;
        max-height: 160px;
        justify-content: space-between;

        @media (max-width: 500px) {
            height: fit-content;
        }
    }

    .snippet {
        display: flex;
        flex-direction: column;
        width: 24%;
        margin-bottom: 20px;
        border: 2px solid $font-main;

        @media (max-width: 1075px) {
            width: 32%;
        }

        @media (max-width: 780px) {
            width: 49%;
        }

        @media (max-width: 500px) {
            flex-basis: 100%;
        }
    }

    .blog-btn {
        font-size: 15px;
        letter-spacing: 1px;
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        width: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $background;
        align-self: center;
        text-align: center;
        justify-content: center;
        padding: 7px 0;
    }

    .content {
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* number of lines to show */
        line-clamp: 5;
        -webkit-box-orient: vertical;
        padding: 0 10px;

        @media (max-width: 500px) {
            margin-bottom: 15px;
        }
    }
}

.article {
    width: 75%;
    margin: 20px 0 100px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
        width: 90%;
    }

    @media (max-width: 850px) {
        width: 100%;
    }

    h1 {
        font-size: 30px;
    }

    h2 {
        margin: 25px 0 10px;
    }

    .header {
        font-size: 22px;
    }

    .post-title {
        font-family: 'Epilogue', sans-serif;
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 35px;
        font-weight: 800;
    }

    .blog-links {
        display: flex;
        align-self: center;
    }

    .blog-link {
        background-color: $buttonblue;
        border-color: $buttonblue;
        color: $font-main;
        padding: 7px 25px;
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 5px;
        transition: 0.5s;

        &:hover {
            background-color: lighten($buttonblue, 5%);
            border-color: lighten($buttonblue, 5%);
            color: $font-main;
        }

        svg {
            margin-left: 3px;
        }
    }

    .text {
        font-size: 17.5px;
        white-space: pre-line;
        line-height: 22px;
    }
}
