.about-section {
    display: flex;
    height: 100vh;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    padding-bottom: 70px;

    @media (max-width: 790px) {
        height: auto;
        padding-bottom: 0px;
    }

    .home-top {
        @media (max-width: 790px) {
            padding-top: 0px;
        }
    }

    .home-wrapper {
        @media (max-width: 790px) {
            padding-top: 50px;
        }
    }

    .home-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        align-items: flex-start;

        @media (max-width: 790px) {
            width: 100%;
            margin-bottom: 90px;
            align-items: center;
        }
    }

    .home-left {
        width: 45%;
        display: flex;
        align-items: center;

        @media (max-width: 790px) {
            display: none;
        }
    }

    .img-middle {
        width: 98%;

        @media (max-width: 790px) {
            display: none;
        }
    }
}

.title {
    font-size: 25px;
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    z-index: 1;
    margin-top: 15px;
    line-height: 28px;

    @media (max-width: 1070px) {
        font-size: 23px;
        line-height: 25px;
    }

    @media (max-width: 970px) {
        font-size: 21px;
        line-height: 23px;
    }

    @media (max-width: 855px) {
        font-size: 19px;
        line-height: 21px;
    }

    @media (max-width: 790px) {
        margin-top: 7px;
    }

    @media (max-width: 510px) {
        font-size: 18px;
        line-height: 20px;
    }

    @media (max-width: 420px) {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1px;
    }
}

.about {
    width: 85%;

    @media (max-width: 1100px) {
        width: 100%;
    }

    .about-title {
        font-weight: bold;
        margin: 20px 0 15px;
        font-size: 22px;

        @media (max-width: 500px) {
            font-size: 18px;
        }

        @media (max-width: 420px) {
            align-self: flex-start;
        }
    }

    .about-txt {
        font-size: 17px;

        @media (max-width: 500px) {
            font-size: 16px;
        }

        li {
            font-weight: 500;
            font-size: 17px;
        }
    }

    .btn-wrapper {
        align-self: center;

        @media (max-width: 420px) {
            align-self: center;
        }
    }
}
