.cntr {
    display: flex;
}

.btn-radio {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;

    &:not(:first-child) {
        margin-left: 8px;
    }

    svg {
        fill: none;
        vertical-align: middle;
        circle {
            stroke-width: 2;
            stroke: $font-main;
        }
        path {
            &.inner {
                stroke-width: 6;
                stroke-dasharray: 19;
                stroke-dashoffset: 19;
            }

            &.outer {
                stroke-width: 2;
                stroke-dasharray: 57;
                stroke-dashoffset: 57;
            }
        }
    }

    input {
        display: none;
        &:checked + svg {
            path {
                transition: all 0.4s ease;
                &.inner {
                    stroke-dashoffset: 38;
                    transition-delay: 0.3s;
                }
                &.outer {
                    stroke-dashoffset: 0;
                }
            }
        }
    }

    span {
        margin-left: 2.5px;
        font-size: 10px;
    }
}
